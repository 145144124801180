/* eslint-disable prettier/prettier */
"use strict";

exports.backOfficeModes = [
    { id: "C", description: "CallBack" },
    { id: "P", description: "PUSH" },
    { id: "B", description: "Callback + PUSH" },
];

exports.useModes = [{
        id: "0",
        description: "El título, al generar el ticket, se asigna en exclusiva al Integrador que lo adquiere​",
    },
    {
        id: "1",
        description: "El título, al generar el ticket, se le da validez para cualquier Integrador en el que el usuario utilice su credencial. En este caso, el sistema admite que un integrador venda títulos multimodales que el usuario pueda utilizar con cualquier otra App o sistema)",
    },
];

exports.paymentModes = [{
        id: "0",
        description: "Prepago. Se procesa el cobro a partir del momento de la adquisición​​",
    },
    {
        id: "1",
        description: "Postpago. Se procesa el cobro en el momento de la validación​",
    },
];

exports.userTypes = [{
        id: "A",
        description: "El título es anónimo y no se aplicará verificación del usuario (userId) durante su consumo (por ejemplo, títulos impresos en papel)​​​",
    },
    {
        id: "P",
        description: "Personal. El ticket adquirido requiere la asignación de un userId durante la compra. Este dato podrá ser verificado por el Operador durante su utilización​​",
    },
    {
        id: "U",
        description: "Único. El título no es recurrente, se genera para su consumo y no tiene repetidos usos, siendo necesario generar uno nuevo​​",
    },
];

exports.cardTypes = [{
        id: "NO",
        description: "El título no lleva ningún soporte de título de transporte físico asociado​​​",
    },
    {
        id: "TP",
        description: "El título lleva asociado un número de tarjeta de Transporte Público (TTP)​​​",
    },
    {
        id: "RT",
        description: "El título lleva asociado un número de tarjeta Renfe&Tú​",
    },
    {
        id: "EV",
        description: "El título lleva asociado un PAN de una tarjeta EMV",
    },
];

exports.types = [{
        id: "P",
        description: "Print. El título se genera en unidades para ser estampado físicamente y tiene características particulares relacionadas con el modo de tratarlo​​​​",
    },
    { id: "U", description: "Units. El título se consume en forma de unidades​" },
    {
        id: "S",
        description: "Seconds. Número de segundos de validez desde el momento del primer uso​​​​",
    },
    {
        id: "H",
        description: "Hours.  Número de horas de validez desde el momento del primer uso​​​​",
    },
    {
        id: "D",
        description: "Days. Número de días de validez desde la adquisición (compra)​​​​",
    },
    {
        id: "A",
        description: "Undefined. El título se genera en tiempo de solicitud y asociado al perfil del usuario con el medio de acceso activo​",
    },
];

exports.transferableTypes = [{
        id: "N",
        description: "El título de transportes no es transferible a otro usuario mPass​​​​",
    },
    { id: "Y", description: "El título es transferible a otro usuario mPass​​​" },
];

exports.actionTypes = [{
        id: "Q",
        description: "El título permite generar un código QR mediante la invocación a qr / generator para ser mostrado a través del Wallet y validable en un dispositivo lector​​​​",
    },
    {
        id: "C",
        description: "El título permite leer un código QR mediante la apertura de la cámara de fotos a través del Wallet para validar a través de un dispositivo validador​​​",
    },
    { id: "B", description: "El título permite tanto los modos Q como C​​​​" },
    {
        id: "V",
        description: "Se trata de un título Virtual que representa a un título físico y que no genera código QR para ser validado por un dispositivo",
    },
];

exports.qrFormats = [{
        id: "S",
        description: "Short. El QR llevará los datos mínimos necesarios para su identificación.No se requiere datos enriquecidos ya que el sistema de validación está conectado al backend",
    },
    {
        id: "M",
        description: "Medium. El QR lleva datos enriquecidos que permiten realizar una validación off - line, aunque existen elementos complementarios que permanecen en el backend​",
    },
    {
        id: "L",
        description: "Long. El QR lleva todos los datos necesarios para una validación completa y autónoma de la transacción, sin necesidad de conectar al backend excepto para notificar su uso​​​​",
    },
];