<template>
  <v-container grid-list-md>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Generar nueva Clave AES
        </v-card-title>

        <container>
          <v-card-text>
            Pulsado aceptar, la aplicación propondrá una nueva clave AES. Una vez generada la nueva
            clave, deberá pulsar guardar para almacenar la nueva clave. Recuerde sustituir la
            antigua clave por la nueva en sus desarrollos.
          </v-card-text>
        </container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="updateAesComplete">
            Aceptar
          </v-btn>
          <v-btn color="primary" text @click="dialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout row wrap>
      <v-flex xs12 md6 my-auto>
        <h1 align-center>{{ integrator.name }}</h1>
      </v-flex>

      <v-flex xs12 md3 class="text-center d-none d-md-block">
        <h3>Nº Operadores</h3>
        <v-chip>{{ integrator.operators.length }}</v-chip>
      </v-flex>

      <v-flex xs6 md3 class="text-center d-none d-md-block">
        <h3>Usuarios registrados</h3>
        <v-chip>{{ integrator.numUsers }}</v-chip>
      </v-flex>
    </v-layout>

    <v-layout row wrap mt-2>
      <v-flex xs12 md6>
        <v-card class="ma-2" elevation="1">
          <v-card-text class="pt-5 pb-1 py-5"><b>Configuración</b></v-card-text>

          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="integrator.aesParaph"
                  append-icon="mdi-content-copy"
                  append-outer-icon="mdi-refresh-circle"
                  prepend-icon="mdi-key"
                  filled
                  :rules="rulesAes"
                  required
                  hide-details="auto"
                  label="Clave AES"
                  :type="aesInputType"
                  @click:append-outer="updateAes"
                >
                  <v-icon slot="append" @click="changeAesInput" title="Ver clave"> mdi-eye</v-icon>
                  <v-icon
                    slot="append"
                    class="ml-2"
                    @click="copyAes"
                    title="Copiar al portapapeles"
                  >
                    mdi-content-copy
                  </v-icon>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn
                  color="accent"
                  :loading="loading"
                  @click="saveOperator"
                  elevation="3"
                  block
                  v-show="canSaveAes"
                  >Guardar AES</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-flex>

      <v-flex xs12 md6>
        <v-card class="ma-2" elevation="1">
          <v-card-text class="pt-5 pb-1 py-5"><b>Back Office</b></v-card-text>

          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="URL"
                  v-model="integrator.urlBackOffice"
                  filled
                  clearable
                  hide-details="auto"
                  prepend-icon="mdi-web"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="integrator.modeBackOffice"
                  :items="backOfficeModes"
                  item-value="id"
                  item-text="description"
                  filled
                  hide-details="auto"
                  label="Modo de petición"
                  prepend-icon="mdi-arrow-decision"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Headers"
                  v-model="integrator.headerBackOffice"
                  filled
                  clearable
                  hide-details="auto"
                  auto-grow
                  row-height="10"
                  prepend-icon="mdi-alpha-h-box-outline"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn color="accent" :loading="loading" @click="saveOperator" elevation="3" block
                  >Guardar</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
const catalogs = require("../shared/catalogs");

export default {
  components: {},
  name: "Integrator",
  data: () => ({
    dialog: false,
    canSaveAes: false,
    aesInputType: "password",
    backOfficeModes: catalogs.backOfficeModes,
    rulesAes: [
      value => !!value || "Clave AES obligatoria.",
      value => (value && value.length === 16) || "Máximo 16 caracteres.",
    ],
  }),
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    integrator() {
      return this.$store.getters.getIntegrator;
    },
  },
  methods: {
    changeAesInput() {
      if (this.aesInputType == "text") this.aesInputType = "password";
      else this.aesInputType = "text";
    },
    copyAes() {
      var aux = document.createElement("input");
      aux.setAttribute("value", this.integrator.aesParaph);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$store.commit("showBanner", {
        bannerMessage: "Clave AES copiada al portapapeles.",
        bannerType: "success",
      });
    },
    updateAes() {
      this.dialog = true;
    },
    updateAesComplete() {
      this.dialog = false;
      this.$store.dispatch("generateAES").then(response => {
        this.integrator.aesParaph = response;
        this.canSaveAes = true;
      });
    },
    saveOperator() {
      this.integrator.aesParaph = this.integrator.aesParaph.toUpperCase();
      this.$store.dispatch("saveIntegrator");
      this.canSaveAes = false;
    },
  },
};
</script>

<style scoped></style>
